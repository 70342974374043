<template>
  <div id="productlist" ref="box">
    <top :name="text" />
    <div class="search_div">
      <van-search
        @click="is"
        style="position: relative; z-index: 9999; flex: 1"
        shape="round"
        background="none"
        v-model="search_value"
        placeholder="请输入商品"
      />
      <div
        @click="searct_fun()"
        class="sou"
        :style="is_sear == true ? 'display: block;' : 'display: none;'"
      >
        搜索
      </div>
    </div>
    <van-dropdown-menu>
      <van-dropdown-item v-model="value1" :options="option1" />
      <van-dropdown-item v-model="value2" :options="option2" />
    </van-dropdown-menu>
    <div class="ul_div" style="display: inline-block; width: 100%">
      <van-list
        :immediate-check="ischeck"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ul class="commend-list">
          <li
            v-for="(item, index) in list_arr"
            :key="index"
            @click="todetails(item.id)"
          >
            <div class="commend-img">
              <img class="" :src="item.image" alt="" />
            </div>
            <h4
              style="
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
              "
            >
              {{ item.name }}
            </h4>
            <div class="commend-sale" style="clear: both">
              <span style="color: red; float: left">{{ item.price }}元+{{item.integral}}积分</span>
              <span style="float: right">销量{{ item.sales }}</span>
            </div>
          </li>
        </ul>
      </van-list>
    </div>
    <!-- 搜索组件 -->
    <sreach
      v-on:resdata="resdata"
      v-on:guanbiseacht="guanbi"
      :data="remen"
      v-if="is_sear == true"
    />
  </div>
</template>

<script>
import top from "../components/filltop.vue";
import sreach from "../components/sreach.vue";
export default {
  data() {
    return {
      text: "商品列表",
      value1: 0,
      ischeck: false,
      value2: "a",
      finished: false,
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      option2: [
        { text: "默认排序", value: "a" },
        { text: "好评排序", value: "b" },
        { text: "销量排序", value: "c" },
      ],
      search_value: "",
      is_sear: false,
      loading: false,
      list_arr: [],
      remen: {},
      page: 1,
      isFirstEnter: false,
    };
  },
  components: {
    top,
    sreach,
  },
  created() {
    this.isFirstEnter = true;
    // 只有第一次进入或者刷新页面后才会执行此钩子函数
    // 使用keep-alive后（2+次）进入不会再执行此钩子函数
    setTimeout(() => {
      this.$refs.box.scrolltop = 10;
    }, 1000);
  },
  deactivated() {
    // 离开页面销毁
    this.page = 1;
    console.log(this.page);
  },
  methods: {
    onLoad() {
      this.search_value = this.$route.params.success;
      console.log(this.search_value, "a");
      var that = this;
      this.loading = true;
      this.page +=  1;
      setTimeout(() => {
        this.$https(
          "goods/list",
          "page=" +
            this.page +
            "&value=" +
            this.search_value +
            "&is_id=" +
            this.$route.params.isfalse,
          "get"
        ).then((res) => {
          if (res.data.data.length != 0) {
            res.data.data.forEach((item, index) => {
              this.list_arr.push(item);
            });
            this.loading = false;
          } else {
            console.log("进入");
            this.finished = true;
          }
        });
      }, 1000);
    },

    todetails(id) {
      // console.log(id)
      this.$https("goods/details", "goods_id=" + id + "+", "post")
        .then((res) => {
          if (res.data.code == 200) {
            this.$router.push({ name: "details", params: { success: res } });
          } else {
            // 提示错误
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searct_fun() {
      this.$route.params.success = this.search_value;
      this.page = 1;
      this.$https(
        "goods/list",
        "page=" +
          this.page +
          "&value=" +
          this.search_value +
          "&is_id=" +
          "false",
        "get"
      ).then((res) => {
        if (res.data.code == 200) {
          this.is_sear = false;
          this.list_arr = res.data.data;
          console.log(this.list_arr);
          // 重置参数
          (this.loading = false), (this.finished = false);
          console.log("重置了参数", this.finished, this.loading);
        } else {
          console.log("请求失败  请重新检查接口");
        }
      });
    },
    guanbi(e) {
      console.log(e);
      this.is_sear = e;
    },
    resdata(e) {
      this.list_arr = e.data;
      console.log(this.list_arr);
    },
    is() {
      this.is_sear = true;
      this.$https("class/hot", "", "get", "false")
        .then((res) => {
          if (res.data.code == 200) {
            this.remen = res.data.data.data;
          } else {
            // 提示错误
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 列表请求接口
    detailslist() {
      this.search_value = this.$route.params.success;
      console.log(this.search_value, "生命");
      this.$https(
        "goods/list",
        "page=" +
          this.page +
          "&value=" +
          this.search_value +
          "&is_id=" +
          this.$route.params.isfalse,
        "get"
      ).then((res) => {
        if (res.data.code == 200) {
          this.list_arr = res.data.data;
          console.log(this.list_arr);
          // 重置参数
          (this.loading = false), (this.finished = false);
          console.log("重置了参数", this.finished, this.loading);
        } else {
          console.log("请求失败  请重新检查接口");
        }
      });
    },
  },
  mounted() {},
  activated() {
    if (!this.$route.meta.isBack || this.isFirstEnter) {
      // 如果isBack是false，重新请求
      this.detailslist();
      console.log("重新请求2");
    } else {
      // 使用缓存数据
    }
    this.$route.meta.isBack = false;
    // 恢复成默认的false，避免isBack一直是true，导致每次都获取新数据
    this.isFirstEnter = false;
  },

  // 路由守卫  判断是否是指定进入
  beforeRouteEnter(to, from, next) {
    if (from.name == "details") {
      to.meta.isBack = true;
    }
    next();
  },
};
</script>

<style src="../viewscss/viewscss.css" scoped>
</style>